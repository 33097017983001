// Project imports
import { config } from "../config.js";
import Request from "../common/Request.js";
import Debug from "../common/Debug.js";

/**
 * @description: A basic front-end service to manage push notifications. To test this service, you can use the following cURL
 * command
 * curl -X POST -H "Content-Type: application/json" \
 *   -H "Authorization: Bearer YOUR-TOKEN-HERE" \
 *   -d '{"userId":"YOUR-USER-ID", "title":"Check in reminder", \
 *   "body":{"message":"Open the app or check in from here","action":"checkinreminder"}}' \
 *    http://localhost:8788/api/admin/notification
 * @todo: Add error handling; align with existing front-end coding style;  consider return success or ok instead of response object
 * @export
 * @class PushNotificationService
 */
export default class PushNotificationService {
  #applicationServerKey;

  /**
   * Creates an instance of PushNotificationService.
   * @param {string} userId:  The Kinde user Id of the current user
   * @param {string} token:   The access token of the current user
   * @param {Object} registration:  The service worker registration object created in main.js
   * @memberof PushNotificationService
   */
  constructor(userId, token = "", registration) {
    this.userId = userId;
    this.token = token;
    this.registration = registration;
    this.permission = Notification?.permission === "granted";
    this.subscription = null;
    this.result = null;
    this.supportsNotifications = "Notification" in window;
    this.#applicationServerKey = config.webPushPublicKey;
    // Log the current state
    Debug.log("PushNotificationService.constructor: initial state", this);
  }

  /**
   * @description: Manages permissions to show notifications in the browser (PoC only and needs to be properly Jake-ified).
   * This should be connected to some sort of button or control in the settings page so that it is user-initiated. It should also be
   * reflected in the UI so the user knows the current state. Don't auto-prompt for permission as that is annoying and will be
   * blocked.
   * @memberof PushNotificationService
   */
  async getPermission() {
    // Log the current action
    Debug.log("PushNotificationService.getPermission: method called");
    if ("Notification" in window) {
      // First time it prompts for permission. Subsequent times are silent and return the current permission state.
      this.permission = await Notification.requestPermission();
      // Log the initial state
      Debug.log("PushNotificationService.getPermission: initial permission state: " + this.permission);
      if (this.permission === "granted") {
        // Log the granted state
        Debug.log("PushNotificationService.getPermission: permission granted");
        // We have permission! Let's retrieve (or create) a subscription
        this.subscription = await this.#getWebPushSubscription();
      } else {
        // Implicit denial. Log for now but consider reflecting in control state (e.g. checkbox) over refreshes
        Debug.log("PushNotificationService.getPermission: permission denied");
      }
    } else {
      // Browser does not support notifications
      Debug.log("PushNotificationService.getPermission: not supported in this browser");
    }
    // Return a reference to itself
    return this;
  }

  /**
   * @description: Retrieves any existing push subscription or creates a new one and returns it
   * @private
   * @return {Object} The push subscription object
   * @memberof PushNotificationService
   */
  async #getWebPushSubscription() {
    // Log the current action
    Debug.log("PushNotificationService.getWebPushSubscription: method called");
    // Wait for the registration to complete
    await navigator.serviceWorker.ready;
    // Log the current action
    Debug.log("PushNotificationService.getWebPushSubscription: worker ready");
    // Attempt to retrieve an existing subscription
    this.subscription = await this.registration.pushManager.getSubscription();
    // Log the current action
    Debug.log("PushNotificationService.getWebPushSubscription: get subscription", this.subscription);
    // Create a new subscription
    if (!this.subscription) {
      // Log the current action
      Debug.log("PushNotificationService.getWebPushSubscription: no subscription, subscribing");
      // eslint-disable-next-line require-atomic-updates
      this.subscription = await this.registration.pushManager.subscribe({
        // Browsers only support true for the userVisibleOnly option which is also the default. Just being verbose here.
        userVisibleOnly: true,
        // The web spec says applicationServerKey which is aka VAPID public key. See /scripts/generatgeVapidKeys.js
        applicationServerKey: this.#applicationServerKey,
      });
    }
    // Log the current action
    Debug.log("PushNotificationService.getWebPushSubscription: posting subscription", this.subscription);
    // Save the subscription to the server
    this.result = await Request.post(`/api/users/${this.userId}/push`, this.subscription, { token: this.token });
    // Log the current action
    Debug.log("PushNotificationService.getWebPushSubscription: post response", this.result);
    // Return the current subscription
    return this.subscription;
  }

  /**
   * @description: Shows a client initiated notification (not a web push initiated notification). Requires explicit permission.
   * @param {string} title:                         The title of the notification
   * @param {string} message:                       The text of the notification
   * @param {string} [icon="/files/projicon.png"]:  The icon to show in the notification
   * @param {string} [badge=icon]:                  The badge to show in the notification
   * @memberof PushNotificationService
   */
  show(title, message, icon = "/files/projicon.png", badge = icon) {
    // Log the current action
    Debug.log("PushNotificationService.show: method called");
    if (this.permission) {
      // Log the current action
      Debug.log("PushNotificationService.show: has permission, showing notification");
      // Fails silently if notification permission has not been explicitly granted
      new Notification(title, { body: message, icon, badge });
    } else {
      // Log the current action
      Debug.log("PushNotificationService.show: not enabled or not available in this browser");
    }
  }
}
