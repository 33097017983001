// Import common configuration
import { config } from "./config.js";

// Import required modules
import { listen, dom, doms } from "./common/DOM.js";
import Request from "./common/Request.js";
import Debug from "./common/Debug.js";
import UserService from "./modules/UserService.js";
import FamilyCarousel from "./modules/FamilyCarousel.js";
import PushNotificationService from "./modules/PushNotificationService.js";

/**
 * The main bootstrapping initialization method.
 */
async function initialize() {
  // Create a new instance of the user service
  this.user = new UserService(config);

  // Create a new instance of the family carousel
  this.carousel = new FamilyCarousel(this.user);

  // Cache the reference to the navigation container and toggle
  const navigation = dom(".header-nav");
  const toggle = dom(".header-toggle");

  // Handle on click of the mobile menu toggle
  listen(toggle, "click", () => {
    const active = navigation.dataset.active === "true";
    navigation.dataset.active = active ? "false" : "true";
    toggle.dataset.active = active ? "false" : "true";
  });

  // Authenticate to attempt to get the token / profile
  await this.user.authenticate();

  // Check if the authentication was successful
  if (await this.user.isAuthenticated()) {
    await handleAuthenticated();
    // Handle long press of settings button to enable debug
    // TODO: Move to a respective file
    handleDebug();
    // Handles the service worker and notifications logic
    handlePushNotifications(this.user);
  } else {
    await handleUnauthenticated();
  }
}

/**
 * Handles the service worker and notifications logic.
 */
async function handlePushNotifications(user) {
  // Log the current action
  Debug.log("main.handlePushNotifications: method called");
  // Check that service workers and notifications are supported in this browser
  if (!("serviceWorker" in navigator && "Notification" in window)) {
    // Log the current action
    Debug.log("main.handlePushNotifications: does not support service workers and/or notifications");
    return;
  }

  // Get arguments for the PushNotificationService constructor
  const registration = await registerServiceWorker();
  const profile = await this.user.getProfile();
  const token = await this.user.getToken();

  // Log the current action
  Debug.log("main.handlePushNotifications: service worker registered");

  // Create a new instance of the pushNotificationService which accepts the user, token, and registration object
  const pushNotificationService = new PushNotificationService(profile.userId, token, registration);

  // Skip if no service was creatd
  if (pushNotificationService == null) {
    return;
  }

  // Log the current action
  Debug.log("main.handlePushNotifications: pushNotificationService instance created");

  // If not on the settings page, skip the rest
  if (!dom(".page-settings")) {
    return;
  }

  // Toggles notifications logic and UI
  togglePushNotifications(pushNotificationService, profile, token, user);
}

/**
 * Toggles notifications logic and UI.
 */
async function togglePushNotifications(pushService, profile, token, user) {
  // Log the current action
  Debug.log("main.togglePushNotifications: method called");
  const subscription = await pushService.registration.pushManager.getSubscription();

  // Log the current action
  Debug.log("main.togglePushNotifications: pushManager subscription", subscription);
  const endpoint = subscription ? await digest(subscription.endpoint) : null;

  // Log the current action
  Debug.log("main.togglePushNotifications: pushManager endpoint", endpoint);
  const active = profile.subscriptions && subscription && profile.subscriptions.indexOf(endpoint) > -1;

  // Log the current action
  Debug.log("main.togglePushNotifications: are notifications enabled - " + active);

  // Update the button text and ensure it is shown
  const button = dom(".button-notifications");
  button.innerHTML = button.dataset[active ? "disable" : "enable"];
  button.classList.remove("hidden");

  // Toggle the subscription on click of the button
  // NOTE: Use the onlick directly to rebind each time instead of binding multiple
  button.onclick = async () => {

    // Log the current action
    Debug.log("main.togglePushNotifications: button clicked, enabled state - " + active);

    if (active) {

      // Log the current action
      Debug.log(`main.togglePushNotifications: deleting subscription via API - /api/users/${profile.userId}/push/${endpoint}`);
      await Request.delete(`/api/users/${profile.userId}/push/${endpoint}`, { token });

    } else {

      // Log the current action
      Debug.log("main.togglePushNotifications: requesting permission");
      await pushService.getPermission();

      // Log the current action
      Debug.log("main.togglePushNotifications: permission state - " + pushService.permission);

      if (pushService.permission === "granted") {

        Debug.log("main.togglePushNotifications: permission granted, showing notification");
        user.sendMessage(profile.userId, "Congrats, you have enabled notifications for FamStat.", "Notification Enabled");

      } else {

        Debug.log("main.togglePushNotifications: permission NOT granted");

      }

    }

    Debug.log("main.togglePushNotifications: getting latest profile and updating UI");
    togglePushNotifications(pushService, await this.user.getProfile(), token, user);

  };

  // Send the test notification
  const test = dom(".button-test");
  test.classList.remove("hidden");
  test.onclick = () => user.sendMessage(
    profile.userId,
    "Testing a notification",
    "Notification Test"
  );
}

/**
 * Registers the service worker.
 */
async function registerServiceWorker() {
  // Log the current action
  Debug.log("main.registerServiceWorker: method called");

  try {

    window.navigator.serviceWorker.addEventListener("message", ({ data }) => {
      Debug.log("serviceWorkerEvent: message of type: " + data.type);
      switch (data.type) {
        case "version":
          Debug.log("serviceWorkerEvent: version", data.version);
          displayVersion(data.version);
          break;
        case "update":
          Debug.log("serviceWorkerEvent: updated", data.version);
          // HACK: The exclamation point is to indicate an updated version
          displayVersion(data.version + "!");
          break;
      }
    });

    console.log("/serviceWorker.js?v" + config.version);

    const registration = await window.navigator.serviceWorker.register(
      "/serviceWorker.js?v" + config.version,
      { scope: "/" }
    );

    // Send a message to the service worker to get the version number
    window.navigator.serviceWorker.controller.postMessage({ type: "version" });

    return registration;

  } catch (error) {
    Debug.log(`main.registerServiceWorker: registration failed with ${error}`);
  }
}

/**
 * The method to handle the authenticated state.
 */
async function handleAuthenticated() {
  // Toggle the header navigation links based on auth state
  dom(".header")?.setAttribute("data-authenticated", "true");

  // Bind event listeners to the logout button
  listen("#logout", "click", async () => await this.user.logout());

  const token = await this.user.getToken();

  // Log the user's current token
  Debug.log("main.handleAuthenticated: access token", token);
  Debug.log("main.handleAuthenticated: token decoded", JSON.parse(atob(token.split(".")[1])));
  Debug.log("main.handleAuthenticated: refresh token", window.localStorage.getItem("kinde_refresh_token"));

  // Fetch the latest user data from the API
  Debug.log("main.handleAuthenticated: FamStat user profile", await this.user.getProfile());

  // Initialize the carousel functionality
  this.carousel.initialize();
}

/**
 * The method to handle the unauthenticated state.
 */
async function handleUnauthenticated() {
  // Toggle the header navigation links based on auth state
  dom(".header")?.setAttribute("data-authenticated", "false");

  // Bind event listeners to the login button
  listen("#login", "click", async () => await this.user.login());

  // Bind event listeners to the register button
  listen("#register", "click", async () => await this.user.register());

  // Redirect to login if not on the landing page
  // Handle edge case where pathname could be empty
  if (window.location.pathname && window.location.pathname !== "/") {
    this.user.auth.client.login({ app_state: { action: "login" } });
  }
}

/**
 * The method to handle displaying the debug version.
 */
function displayVersion(version) {
  if (dom(".debug-version")) {
    return dom(".debug-version").innerHTML = version;
  }

  const element = document.createElement("div");
  element.className = "debug-version";
  element.innerHTML = version;
  document.body.appendChild(element);
}

/**
 * The method to handle long press of settings button to enable debug.
 * TODO: Move to a respective file.
 */
async function handleDebug() {

  const button = dom(".button-settings");

  if (!button) {
    return;
  }

  let timerId;

  const startPress = () => {
    timerId = window.setTimeout(() => {
      window.location.href = "/settings#debug";
    }, 3000);
  };

  const endPress = () => {
    window.clearTimeout(timerId);
  };

  button.addEventListener("mousedown", startPress);
  button.addEventListener("touchstart", startPress);
  button.addEventListener("mouseup", endPress);
  button.addEventListener("touchend", endPress);
  button.addEventListener("mouseleave", endPress);
  button.addEventListener("touchcancel", endPress);

}

/**
 * The method to handle hashing.
 */
async function digest(message, algo = "SHA-1") {
  return Array.from(new Uint8Array(await crypto.subtle.digest(algo, new TextEncoder().encode(message))), (byte) =>
    byte.toString(16).padStart(2, "0")
  ).join("");
}

// Wait until the DOM content has loaded before initializing
listen(window, "DOMContentLoaded", initialize);
