// Import common types
import { USER_MOOD } from "../config.js";

// Import required modules
import { listen, dom, doms } from "../common/DOM.js";
import Debug from "../common/Debug.js";

/**
 * A class to handle the scrollable / swipeable family carousel.
 */
class FamilyCarousel {

  /**
   * The constructor for the class.
   */
  constructor(user) {

    // Reference to the current user and user service
    this.user = user;

    // Cache the reference to the container
    this.carousel = dom(".carousel");

  }

  /**
   * Method to initialize carousel.
   */
  async initialize() {

    // Skip if no carousel present in the DOM
    if (!this.carousel) {
      return;
    }

    // Read the families
    this.renderFamilies();

    // Watch for scroll and update pagination based on scroll amount
    listen(this.carousel, "scroll", () => {
      this.updatePagination(
        Math.round(this.carousel.scrollLeft / window.innerWidth)
      );
    });

    // Handle on click out to close
    listen("body", "click", this.hideMemberPopovers.bind(this));

    // Handle on click of the My Check In and status buttons
    listen(".button-checkin", "click", this.toggleStatusPopover.bind(this));
    listen(".button-checkin-status", "click", this.updateStatus.bind(this));

    // Handle on click of the refresh button
    listen(".button-refresh", "click", this.renderFamilies.bind(this));

    // Handle on click of the add new family button
    listen(".button-add", "click", this.updateFamily.bind(this));

    // Show the carousel now that data is rendered
    this.carousel.classList.add("active");

  }

  /**
   * Method to render families.
   */
  async renderFamilies() {

    const families = (await this.user.readFamilies()).reverse();

    // Debug.log("FamilyCarousel.renderFamilies: data", families);

    // Get references to the carousel container
    const container = dom("[data-carousel-wrapper]", this.carousel);
    let template = "";

    for (let familyIndex = 0; familyIndex < families.length; familyIndex++) {

      const family = families[familyIndex];
      let members = "";

      family.members.forEach((member, memberIndex) => {

        const id = `family-${familyIndex}-member-${memberIndex}`;
        const checkin = this.formatCheckInTimestamp(member.updatedAt);

        let width = 100;
        let color = "";
        if (checkin.ratio >= -1) {
          width = Math.min(width + checkin.ratio * 100, width);
          color = "green";
        } else {
          width = Math.min((width - (checkin.ratio * -100)) * -1, width);
          color = "red";
        }

        let mood = "";
        let icon = "";
        switch (member.mood) {
          case USER_MOOD.USER_FROWN:
            mood = "Frown";
            icon = "frown";
            break;
          case USER_MOOD.USER_NEUTRAL:
            mood = "Neutral";
            icon = "neutral";
            break;
          case USER_MOOD.USER_SMILE:
            mood = "Smile";
            icon = "smile";
            break;
        }

        members += [
          '<li class="family-member ', color, '" data-status="', member.mood, '">',
            '<div class="family-member-avatar"></div>',
            '<button class="family-member-toggle"',
                'id="', id, '-button"',
                'aria-haspopup="true"',
                'aria-expanded="false"',
                'aria-controls="', id, '-popover">',
              member.firstName, ' ', member.lastName,
            '</button>',
            '<div class="family-member-status">',
              '<img src="files/icon-status-', icon, '.svg" alt="', icon, '">',
            '</div>',
            '<div class="family-member-bar ', color, '" ',
                'style="width: ', width, '%;">',
            '</div>',
            '<div class="family-member-popover"',
                'id="', id, '-popover"',
                'role="dialog"',
                'aria-hidden="true">',
              '<div>',
                '<b>', 'Checked-In At', '</b>',
                checkin.formatted,
              '</div>',
              '<div>',
                '<b>', 'Time to Next Check-In', '</b>',
                checkin.remaining, ' Hours',
              '</div>',
              '<div>',
                '<b>', 'Mood', '</b>',
                mood,
              '</div>',
            '</div>',
          '</li>',
        ].join("");

      });

      template += [
        '<li class="families-item" data-carousel-item="', family.familyId, '">',
          '<section class="wrapper">',
            '<h1>', family.familyName, '</h1>',
            '<button class="families-item-add button"',
                'data-action="add"',
                'data-family-id="', family.familyId, '">',
              'Add',
            '</button>',
            '<ul class="family">', members, '</ul>',
          '</section>',
        '</li>',
      ].join("");

    }

    // Remove all children first, except the last one (add new screen)
    [...container.children].slice(0, -1).forEach(
      (child) => container.removeChild(child)
    );

    // Insert new template string before the last element
    container.insertAdjacentHTML("afterbegin", template);

    // Handle on click of the family member status to toggle popover
    listen(".family-member-toggle", "click", this.toggleMemberPopover.bind(this));

    // Handle on click of the family member status to toggle popover
    listen(".families-item-add", "click", this.addFamilyMember.bind(this));

    // Reset the scroll position as not all browsers do this
    dom(".carousel").scrollLeft = 0;

    // Render the updated pagination
    this.renderPagination();

  }

  /**
   * The method to handle toggling status popover.
   */
  async toggleStatusPopover() {

    const button = dom("#actionbar-checkin");
    const popover = dom("#actionbar-popover");
    const active = button.getAttribute("aria-expanded") === "true";

    button.setAttribute("aria-expanded", active ? "false" : "true");
    popover.setAttribute("aria-hidden", active ? "true" : "false");

  }

  /**
   * The method to handle updating status.
   */
  async updateStatus({ target }) {

    // TODO: Separate the click handler from the update method

    await this.user.updateUserStatus({
      status: USER_MOOD[target.dataset.mood],
      // TODO: Set correct location
      location: { lat: 123.0, lng: 456.789 },
    });

    // Re-render the families
    this.renderFamilies();

  }

  /**
   * The method to handle adding/updating new families.
   */
  async updateFamily({ target }) {

    // The button has multiple uses
    switch (target.dataset.action) {

      // Add a new family
      case "add":

        const response = await this.user.createFamily({
          name: dom("#input-family-add").value,
        });

        if (!response.ok) {
          Debug.log("UserService.updateFamily: ", response);
          return window.alert("An error occurred.");
        }

        this.familyId = (await response.json()).familyId;

        // Create the list item and input element
        const li = document.createElement("li");
        const input = this.createMemberInput();

        // Append the input to the list item and to the list
        li.appendChild(input);
        dom(".family-add-members").appendChild(li);

        // Update the button state
        target.dataset.action = "update";
        target.innerText = "Save";

        // Focus on the next input item
        input.focus();

        break;

      // Update the family
      case "update":

        const inputs = doms(".family-add-members .family-add-input");
        let success = true;

        for (let index = 0; index < inputs.length; index++) {
          let input = inputs[index];
          if (!input.value) {
            continue;
          }
          let response = await this.user.createFamilyMember(this.familyId, {
            username: input.value,
          });
          // TODO: Should error stop adding the rest?
          if (!response.ok) {
            success = false;
            // continue;
          }
          input.value = "";
          // NOTE: This commented code will temporarily remain for debugging purposes
          // // Get the response data to use signed invitation
          // const invitation = await response.json();
          // // Confirm the family member to bypass the email verification
          // await this.user.confirmFamilyMember(this.familyId, invitation);
        }

        if (!success) {
          return window.alert("An error occurred.");
        }

        // Reset the family name
        // TODO: Add a form element and use .reset()
        dom("#input-family-add").value = "";

        // Re-render the families
        this.renderFamilies();

        break;

    }

  }

  /**
   * The method to handle adding new family members to the family list.
   */
  async addFamilyMember({ target }) {

    const { familyId, action } = target.dataset;
    const list = dom(`.families-item[data-carousel-item="${familyId}"] .family`);

    // TODO: Refactor this with the updateFamily method

    // The button has multiple uses
    switch (action) {

      // Add a new family
      case "add":

        // Create the list item and input element
        const li = document.createElement("li");
        const input = this.createMemberInput(false);

        // Append the input to the list item and to the list
        li.appendChild(input);
        list.appendChild(li);

        // Update the button state
        target.dataset.action = "update";
        target.innerText = "Save";

        break;

      // Update the family
      case "update":

        const inputs = doms(".family-add-input", list);
        let success = true;

        for (let index = 0; index < inputs.length; index++) {
          let input = inputs[index];
          if (!input.value) {
            continue;
          }
          let response = await this.user.createFamilyMember(familyId, {
            username: input.value,
          });
          // TODO: Should error stop adding the rest?
          if (!response.ok) {
            success = false;
          }
        }

        if (!success) {
          return window.alert("An error occurred.");
        }

        // Re-render the families
        this.renderFamilies();

      break;

    }

  }

  /**
   * The method to handle adding new inputs to the family list.
   */
  addMemberInput() {

    const list = dom(".family-add-members");
    const inputs = doms("input", list);
    const last = inputs[inputs.length - 1];

    if (last.value === "") {
      return;
    }

    // Create the list item and input element
    const li = document.createElement("li");
    const input = this.createMemberInput();

    // Append the input to the list item and to the list
    li.appendChild(input);
    list.appendChild(li);

  }

  /**
   * The method to handle creating new inputs for the family list.
   */
  createMemberInput(addListeners = true) {

    const input = document.createElement("input");

    input.className = "family-add-input";
    input.placeholder = "Family Member Email Address";

    if (addListeners) {
      input.addEventListener("input", this.addMemberInput.bind(this));
      input.addEventListener("blur", this.removeMemberInput.bind(this));
    }

    return input;

  }

  /**
   * The method to handle removing empty inputs.
   */
  removeMemberInput() {

    const list = dom(".family-add-members");
    const inputs = doms("input", list);

    for (let index = 0; index < inputs.length - 1; index++) {
      const input = inputs[index];
      if (input.value === "") {
        input.parentNode.parentNode.removeChild(input.parentNode);
      }
    }

  }

  /**
   * The method to handle toggling the family member popover.
   */
  toggleMemberPopover({ target }) {

    const id = target.getAttribute("aria-controls");
    const expand = target.getAttribute("aria-expanded") === "false";

    this.toggleMemberPopoverById(id, expand);

  }

  /**
   * The method to handle toggling the family member popover by ID.
   */
  toggleMemberPopoverById(id, expand) {

    const buttons = doms(".family-member-toggle");
    const toggle = dom(`button[aria-controls='${id}']`);
    const popover = dom("#" + id);

    if (!expand) {
      toggle.setAttribute("aria-expanded", "false");
      popover.setAttribute("aria-hidden", "true");
      popover.classList.remove("above", "below");
      return;
    }

    buttons.forEach((button) => {
      button.setAttribute("aria-expanded", "false");
      const controls = dom("#" + button.getAttribute("aria-controls"));
      controls.setAttribute("aria-hidden", "true");
      controls.classList.remove("above", "below");
    });

    toggle.setAttribute("aria-expanded", "true");
    popover.setAttribute("aria-hidden", "false");

    if (toggle.getBoundingClientRect().top < window.innerHeight / 2) {
      popover.classList.add("below");
    } else {
      popover.classList.add("above");
    }

  }

  /**
   * The method to handle hiding the family member popovers.
   */
  hideMemberPopovers({ target }) {

    const popovers = doms(".family-member-popover[aria-hidden='false']");

    // Loop over all popovers
    popovers.forEach((element) => {
      // Skip if the click is on the toggle button
      if (target.classList.contains("family-member-toggle")) {
        return;
      }
      // Skip if the click is within the active popover
      if (element.contains(target)) {
        return;
      }
      // Hide the current popover
      this.toggleMemberPopoverById(element.id, false);
    });

  }

  /**
   * Method to render pagination dots.
   */
  renderPagination() {

    // Get references to the pagination container and count
    const container = dom("[data-carousel-pagination]", this.carousel);
    const count = doms("[data-carousel-item]", this.carousel).length;

    // Skip if no container found in the DOM
    if (!container) {
      return;
    }

    // Clear previous dots
    container.innerHTML = "";

    // Create a dot for each item in the swipeable component
    for (let index = 0; index < count; index++) {

      // Create the pagination element
      const dot = document.createElement("button");

      // Set the classes and a11y attributes
      dot.classList.add("dot");
      dot.classList.toggle("active", index === 0);
      dot.tabIndex = index === 0 ? 0 : -1;
      dot.setAttribute("aria-label", `Go to slide ${index + 1}`);
      dot.setAttribute("data-carousel-pagination-item", "");

      // Add a click event listener to navigate to the respective index
      listen(dot, "click", () => {
        this.carousel.scrollTo({
          left: index * window.innerWidth,
          behavior: "smooth",
        });
      });

      // Enable keyboard interaction, allowing navigation using the Enter/Space keys
      listen(dot, "keydown", (event) => {
        if (event.code === "Enter" || event.code === "Space") {
          dot.click();
        }
      });

      // Append to the list of dots
      container.appendChild(dot);

    }

  }

  /**
   * Method to update pagination dots based on the index.
   * @param {number} index - Index of the active dot.
   */
  updatePagination(index) {

    // Query all pagination items within the current carousel
    const dots = doms("[data-carousel-pagination-item]", this.carousel);

    // Remove active class from all dots
    dots.forEach((dot) => {
      dot.classList.remove("active");
      dot.removeAttribute("aria-current");
    });

    // Add active class to the dot representing the index
    dots[index].classList.add("active");
    dots[index].setAttribute("aria-current", "true");

  }

  /**
   * Method to format the timestamp date and create the check-in ratio.
   * @param {String} timestamp - The date timestamp with timezone offset.
   */
  formatCheckInTimestamp(timestamp) {

    // Create a Date object using the timestamp string
    const date = new Date(timestamp);

    // Specify the options for formatting the date for display
    const options = {
      month: "long",
      weekday: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    // Create the formatter instance and format the date
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formatted = formatter.format(date);

    // Define constants for time calculations
    const hour = 60 * 60 * 1000;
    const day = 24 * hour;

    // Get the current date/time and 24 hours ahead
    const now = new Date();
    const tomorrow = new Date(date.getTime() + day);

    // Calculate the ratio
    // -1 is 24 hours or more behind current date
    //  0 is the current date
    // +1 is 24 hours or more ahead of current date
    const ratio = (date - now) / day;

    // Calculate the next check-in time remaining
    const remaining = Math.floor((tomorrow - now) / hour);

    return { formatted, ratio, remaining };

  }

}

export default FamilyCarousel;
