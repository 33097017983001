/**
 * Module with shorthand methods to query the DOM.
 * @module DOM
 */

/**
 * Method to query the DOM for a single element.
 * @param {String} selector  The DOM selector to query with.
 * @param {HTMLElement} parent  The optional parent element to query, defaulting to document.
 * @memberof DOM
 * @returns {HTMLElement}
 */
export const dom = (selector, parent = null) => {

  // If no parent is provided, use the document root
  parent = parent || document;

  // Return the first result from the query
  return parent.querySelector(selector);

};

/**
 * Method to query the DOM for multiple elements.
 * @param {String} selector  The DOM selector to query with.
 * @param {HTMLElement} parent  The optional parent element to query, defaulting to document.
 * @memberof DOM
 * @returns {NodeLisOf<HTMLElement>}
 */
export const doms = (selector, parent = null) => {

  // If no parent is provided, use the document root
  parent = parent || document;

  // Query the DOM for the selector and return the element(s)
  return parent.querySelectorAll(selector);

};

/**
 * Method to attach the event handler to the element(s) provided.
 * @param {any} elements  The DOM element(s) to attach the handler to.
 * @param {String} event  The name of the event to add the listener for.
 * @param {Function} handler  The handler to attach to the element.
 * @param {boolean} capture  Specify the event phase to target.
 * @memberof DOM
 */
export const listen = (elements, event, handler = () => {}, capture = false) => {

  // Convert a selector string to a NodeList by querying the DOM
  // This ensures only DOM elements are handled below
  if (typeof elements === "string") {
    elements = doms(elements);
  }

  // Convert a Node element or the window reference to a single item array
  // This ensures only iterable lists are handled below
  if (elements instanceof Node || elements === window) {
    elements = [elements];
  }

  // Convert a NodeList of elements to an array
  // This ensures only arrays are handled below, as NodeLists don't support map
  elements && [...elements].map((element) =>
    // Attach the event handler to each element
    // TODO: Does the handler need to be binded to a context?
    element && element.addEventListener(event, handler, capture)
  );

};
