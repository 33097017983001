// Import required modules
import AuthService from "./AuthService.js";
import Request from "../common/Request.js";
import Debug from "../common/Debug.js";

/**
 * A service to abstract interfacing with the user.
 */
export default class UserService {

  /**
   * Creates an instance of the class.
   */
  constructor(options = {}) {

    // Cache the provided options
    this.options = options;

    // Create a new instance of the auth service
    this.auth = new AuthService(this.options.auth);

    // Placeholders for data returned via API
    this.profile = null;

  }

  /**
   * The method to handle authentication and to get the token/profile.
   */
  async authenticate() {

    // Authenticate the user via the auth provider
    await this.auth.authenticate();

    const params = window.location.search.slice(1);

    // If the user is authenticated
    if (await this.isAuthenticated()) {
      let signedInvitation = "";
      let familyId = null;
      // Check if the user is registering
      if (params.includes("auth=register")) {
        // Get the email address from the auth profile
        // TODO: using getUser() returns local profile, but token will expire
        const profile = await this.auth.client.getUserProfile();
        try {
          // Create the user and return the profile
          this.profile = await this.createUser(profile);
          // Log the results
          Debug.log("UserService.authenticate: user registered", this.profile);
        } catch (exception) {
          Debug.log("UserService.authenticate: exception", exception);
        }
        // Check if the user is returning from auth client with invite
        if (params.includes("accepted=")) {
          // Parse the token to get the family ID
          signedInvitation = params.split("accepted=")[1];
          familyId = this.parseTokenPayload(signedInvitation).familyId;
          // Log the results
          Debug.log("UserService.authenticate: user registered", { familyId, signedInvitation });
          // Confirm the member via the API
          await this.confirmFamilyMember(familyId, { signedInvitation });
        }
      }
      // Check if the user is accepting the invite and is already logged in
      if (params.includes("invitation=")) {
        // Parse the token to get the family ID
        signedInvitation = params.split("invitation=")[1];
        familyId = this.parseTokenPayload(signedInvitation).familyId;
        // Log the results
        Debug.log("UserService.authenticate: user accepted", { familyId, signedInvitation });
        // Confirm the member via the API
        await this.confirmFamilyMember(familyId, { signedInvitation });
      }
      // Clear the params from the URL
      const url = window.location.href.split("?")[0];
      window.history.pushState({}, null, url);
    // If the user is unauthenticated
    } else {
      // Check if the user is accepting the invite
      if (params.includes("invitation")) {
        await this.acceptInvite(params.split("invitation=")[1]);
      }
    }

  }

  /**
   * The method to handle parsing the token payload as JSON.
   */
  parseTokenPayload(token) {

    // TODO: Handle any errors gracefully

    const payload = token.split(".")[1];
    const decoded = window.atob(payload);
    const parsed = JSON.parse(decoded);

    return parsed.data;

  }

  /**
   * The method to handle accepting the invite via the auth provider.
   */
  async acceptInvite(signedInvitation) {

    return this.auth.client.register({
      app_state: { action: "register", signedInvitation },
    });

  }

  /**
   * The method to handle registering the user in via the auth provider.
   */
  async register() {

    return this.auth.client.register({ app_state: { action: "register" } });

  }

  /**
   * The method to handle logging the user in via the auth provider.
   */
  async login() {

    return this.auth.client.login({ app_state: { action: "login" } });

  }

  /**
   * The method to handle logging the user out via the auth provider.
   */
  async logout() {

    return this.auth.client.logout({ app_state: { action: "logout" } });

  }

  /**
   * The method to check if the user is authenticated.
   */
  isAuthenticated() {

    return this.auth.client.isAuthenticated();

  }

  /**
   * The method to get the user's profile.
   */
  async getProfile() {

    // Get the email address from the auth profile
    // TODO: using getUser() returns local profile, but token will expire
    const { email } = await this.auth.client.getUserProfile();

    // Read the user from the database
    this.profile = await this.readUser(email);

    return this.profile;

  }

  /**
   * The method to get the user's token.
   */
  getToken() {

    return this.auth.client.getToken();

  }

  /********************************************
   * Local methods wrapping the API endpoints *
   *******************************************/

  /**
   * @description: Create a new user in the database. Intended to be the kindeClient.on_redirect_callback for registration. Note
   * that the kindeClient does not reject duplicate registration requests for the same email address. It does however, automatically
   * log the user in if they are already registered.
   * @date 2023-07-08
   * @param {object} kindeUser
   * @memberof UserService
   */
  async createUser({ id, email, given_name, family_name }) {

    // Create a FamStat user from Kinde user
    const user = {
      userId: id,
      emailAddress: email,
      firstName: given_name,
      lastName: family_name,
      // HACK: This field was required or an error is returned
      note: "",
    };

    // Attempt to create the FamStat user in the system
    const response = await Request.post("/api/users", user, {
      token: this.auth.token,
    });

    if (response?.status === 409) {
      // TODO: Should we try to log the user in here?
      // Or send then to the login page? Or just tell them to login?
      Debug.log("UserService.createUser: user may have clicked register instead of login - TODO: handle this");
    }

    // TODO: The response should be sanitized

    // Read back the new user record
    return this.readUser(email);

  }

  /**
   * @description: Read a user from the database by username.
   * @date 2023-07-08
   * @memberof UserService
   * TODO Extend this to read the user's family(ies) as sub-objects
   */
  async readUser(username) {

    const response = await Request.get(`/api/users/${username}`, {
      token: this.auth.token,
    });

    if (response?.ok) {
      return response.json();
    }

    if (response.status === 403 && response.statusText === "User is suspended") {
      window.alert("This account has been suspended. Please contact support.");
      this.kinde.logout();
      // TODO build this into globalish function or middleware so that 401s and 403s can be properly surfaced, ui logged out, etc.
    } else {
      Debug.log("UserService.readUser:  error", response);
    }

  }

  /**
   * Updates a user via the API.
   */
  async updateUserByUsername() {}

  /**
   * Creates a status via the API.
   */
  async updateUserStatus({ status, location }) {

    // Create an object from the status and location
    const payload = { status, location };
    const username = this.profile.emailAddress;

    // Attempt to update the status via the API
    const response = await Request.put(`/api/users/${username}/status`, payload, {
      token: this.auth.token,
    });

    // TODO: The response should be sanitized
    Debug.log("UserService.updateUserStatus: response", response);

  }

  /**
   * Reads the families via the API.
   */
  async readFamilies() {

    const response = await Request.get("/api/families", {
      token: this.auth.token,
    });

    // TODO: The response should be sanitized
    // Debug.log("UserService.readFamilies: response", response);

    if (response?.ok) {
      return response.json();
    }

  }

  /**
   * Creates a new family via the API.
   */
  async createFamily({ name }) {

    // Create a new family object from the name
    const user = { name };

    // Attempt to create the family in the system
    return await Request.post("/api/families", user, {
      token: this.auth.token,
    });

  }

  /**
   * Reads a family via the API.
   */
  async readFamilyByFamilyId(familyId) {

    const response = await Request.get(`/api/families/${familyId}`, {
      token: this.auth.token,
    });

    if (response?.ok) {
      return response.json();
    }

  }

  /**
   * Creates a new family member via the API.
   */
  async createFamilyMember(familyId, user) {

    // Attempt to create the family member in the system
    return await Request.post(
      `/api/families/${familyId}/invitations`,
      user,
      { token: this.auth.token }
    );

  }

  /**
   * Confirms a new family member via the API.
   */
  async confirmFamilyMember(familyId, payload) {

    // Attempt to create the family member in the system
    return await Request.post(
      `/api/families/${familyId}/members`,
      payload,
      { token: this.auth.token }
    );

  }

  /**
   * Sends a message via the API.
   */
  async sendMessage(userId, text, subject = "Notification") {

    return await Request.post("/api/messages", {
      text,
      subject,
      transport: {
        push: {
          replyTo: "replySubId",
          to: [userId],
          action: "CUT",
        },
      },
    },
    {
      token: this.auth.token
    });

  }

}
