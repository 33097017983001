/**
 * A module to handle making HTTP requests.
 * @module Request
 */
class Request {

  /**
   * Method to make an HTTP GET request.
   * @param {String} url  The URL to request.
   * @param {Object} options  The options to provide.
   */
  async get(url, options = {}) {

    options.method = "GET";

    return await this.fetch(url, options);

  }

  /**
   * Method to make an HTTP POST request.
   * @param {String} url  The URL to request.
   * @param {Object} data  The data to send in the request body.
   * @param {Object} options  The options to provide.
   */
  async post(url, data = {}, options = {}) {

    options.data = data;

    return await this.fetch(url, options);

  }

  /**
   * Method to make an HTTP PUT request.
   * @param {String} url  The URL to request.
   * @param {Object} data  The data to send in the request body.
   * @param {Object} options  The options to provide.
   */
  async put(url, data = {}, options = {}) {

    options.method = "PUT";
    options.data = data;

    return await this.fetch(url, options);

  }

  /**
   * Method to make an HTTP DELETE request.
   * @param {String} url  The URL to request.
   * @param {Object} options  The options to provide.
   */
  async delete(url, options = {}) {

    options.method = "DELETE";

    return await this.fetch(url, options);

  }

  /**
   * Method to make an HTTP request via fetch.
   * @param {String} url  The URL to request.
   * @param {Object} options  The options to provide.
   */
  async fetch(url, options = {}) {

    const headers = options.headers || [];
    const auth = options.auth || options.token;
    const json = options.json !== false;

    // Add the JSON content type unless explicity set to false
    if (json) {
      headers.push(["Content-Type", "application/json"]);
    }

    // Add the auth token unless explicity set to false
    if (auth !== false) {
      // Default to the value in the options
      let token = options.token || auth;
      // If a token value is found, add the authorization header
      if (token) {
        // HACK: The lowercase header is a temporary workaround
        headers.push(["authorization", `Bearer ${token}`]);
      }
    }

    // Make the fetch request with the provided values or defaults
    return window.fetch(url, {
      method: options.method || "POST",
      mode: options.mode || "cors",
      body: options.data ? JSON.stringify(options.data) : null,
      headers,
    });

  }

}

// Export the class as a singleton / static class by creating an instance
export default new Request();
