/**
 * A module to handle debugging.
 * @module Debug
 */
class Debug {

  /**
   * Creates an instance of the class.
   */
  constructor() {

    // Only enable if explicitly specified in the URL
    this.enabled = window.location.hash.includes("debug");

    // Track all messages in the order received
    this.messages = [];

    // Create the debug UI panel for displaying
    this.element = document.createElement("ul");
    this.element.setAttribute("id", "debug");

    // If enabled, append the debug element to the DOM and add class
    if (this.enabled) {
      document.body.appendChild(this.element);
      document.body.classList.add("debug");
    }

  }

  /**
   * Method to log the message.
   */
  log(message, data = null) {

    // If data was provided, format it for viewing
    if (data) {
      message += "\n" + JSON.stringify(data, null, 2);
    }

    // Keep track of every message
    this.messages.push(message);

    // Log the message to the console
    console.log(message);

    // Replace new lines with HTML line breaks
    message = message.replace(/(?:\r\n|\r|\n)/g, "<br>");

    // Add the message to the debug element list
    this.element.innerHTML += `<li>${message}</li>`;

  }

}

// Export the class as a singleton / static class by creating an instance
export default new Debug();
