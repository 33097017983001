// Import shared types from API
export { MEMBER_STATE, USER_MOOD } from "../../services/common/types.d.js";

// Common project configuration
export const config = {
  // Get the current version number from the package.json
  version: "0.4.2",
  auth: {
    domain: "https://auth.famstat.com",
    audience: "https://famstat.com/api",
    client_id: "7b47e064faf945a682705177d10937ae",
    redirect_uri: `${window.location.origin}/status`,
    logout_uri: `${window.location.origin}`,
    // Setting false for now; may need to check current environment to use on Stage
    is_dangerously_use_local_storage: true,
  },
  webPushPublicKey: "BF273WF8x_o0Pgp8_zi3tNCNi3hEJUDa2W8MYUPImD2iW9MNwHAFYvL7Dj55vShzl2__E99Uqdu4JaAH99dRxpw",
}
