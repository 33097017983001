// Import third party modules
import createKindeClient from "@kinde-oss/kinde-auth-pkce-js";

/**
 * A service to abstract interfacing with the auth client.
 */
export default class AuthService {

  /**
   * Creates an instance of the class.
   */
  constructor(options = {}) {

    // Cache the provided options
    // TODO: Use Cloudflare secrets or environment variables to store the client ID and domain
    this.options = options;

    // Placeholder for the auth client created in the authenticate method
    this.client = null;

  }

  /**
   * The method to handle authentication and to get the token/profile.
   */
  async authenticate() {

    // Create a new instance of the Kinde client for authentication and authorization
    this.client = await createKindeClient({
      ...this.options,
      on_redirect_callback: this.handler,
    });

    // Attempt to get the user's token from the Kinde client
    // Note that the Kinde client takes care of persisting the token
    // Currently stored in local storage, but that can be changed
    this.token = await this.client.getToken();

  }

  /**
   * The method to handle on redirect callback.
   */
  async handler(authUser, appState) {

    // No user, so we're logging out, probably
    if (!authUser) {
      // According to auth provider docs we should never get here
      console.warn("on_redirect_callback", "no auth user provided");
    }

    let url = "/status";
    let invite = null;

    // Check if the signed invite is cached, the remove it
    if (appState.signedInvitation) {
      invite = appState.signedInvitation;
      delete appState.signedInvitation;
    }

    // Redirect the user to continue the flow
    switch (appState?.action) {
      case "register":
        url += "?auth=register";
        if (invite) {
          url += "&accepted=" + invite;
        }
        break;
      default:
        if (invite) {
          url += "?accepted=" + invite;
        }
        break;
    }

    window.location.href = url;

  }

}
