// User status enums - Define the user's status within the application
// TODO: Consider breaking into separate enums for user state and user mood
export const USER_MOOD = Object.freeze({
  USER_CREATED: 1,
  USER_UPDATED: 2,
  USER_DELETED: 3,
  USER_SUSPENDED: 4,
  USER_UNSUSPENDED: 5,
  USER_FROWN: 10,
  USER_NEUTRAL: 11,
  USER_SMILE: 12,
});

// Family member status enums - Define the user's role within any given family via the Families table
export const MEMBER_STATE = Object.freeze({
  OWNER: 1,
  ADMIN: 2,
  MEMBER: 4,
  INVITED: 8,
  REMOVED: 16,
});

// Message transport enums - Define over what protocol a message might be sent
export const MESSAGE_TRANSPORT = Object.freeze({
  EMAIL: 1,
  SMS: 2,
  PUSH: 3,
});
